import useWindowDimensions from "../../Modules/Other/viewport";

import { EffectCards, Pagination } from 'swiper';
import { Swiper } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/pagination';
import "swiper/css/effect-cards";

import "../../../index.css";

export type PackagesBuilderWrapperProps = {
    style_standard?: string;
    style_swiper?: string;
    children: JSX.Element | JSX.Element[];
}

export default function PackagesBuilderWrapper(props: PackagesBuilderWrapperProps) {
    const { width, threshold_lg } = useWindowDimensions();
    if (width < threshold_lg) {
        return (
            <Swiper effect={"cards"} modules={[EffectCards, Pagination]}
                cardsEffect={{ perSlideRotate: 0, slideShadows: false, perSlideOffset: 15 }}
                spaceBetween={5} slidesPerView={"auto"} autoHeight={false} centeredSlides={true} grabCursor={true}
                pagination={{ clickable: true, dynamicBullets: true, }}
                className={`mySwiper packages_swiper ${props.style_swiper}`}>
                {props.children}
            </Swiper>);
    } else { return <div className={`${props.style_standard}`}> {props.children} </div>; }
}
