import React from "react";
import ServicesBuilder from "./service_builder";

const Services: React.FC = () => {
    return (
        <section data-main-section="Services">
            <div className="4xl:px-[25vw] 3xl:px-[11vw] 2xl:px-[9vw] xl:px-20 px-7
            flex flex-col w-full justify-center gap-8 md:gap-[5rem] pt-14 pb-28 md:pt-24 md:pb-40 text-skin-dark">
                <div className="md:w-[50%] flex flex-col gap-7">
                    <h1 data-tag="services_heading_1" className="font-custom_bold text-2xl md:text-3xl text-skin-h_dark"> </h1>
                    <p data-tag="services_paragraph_1" className="font-custom_regular text-[1.3rem] md:text-[165%] text-skin-p_dark" > </p>
                </div>

                <div className="flex flex-row justify-between w-full">
                    <ServicesBuilder prop={"services_service"} />
                </div>
            </div>
        </section>
    );
};

export default Services;