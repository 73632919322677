const BackgroundSVG = (props: any) => (
  <svg
    viewBox="0 0 1443 1224"
    preserveAspectRatio="none"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path d="M-3 970.5v-859L1440 .5v1224L-3 970.5Z" className="fill-skin-hero_fill transition-colors duration-500" />
  </svg>
)

export default BackgroundSVG
