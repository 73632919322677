import axios from 'axios';

const Request = (file: string) => {
    return axios.get(file)
        .then(({ data }: any) => {
            return data;
        }).catch(() => {
            console.log('file not found');
        });
};

interface requestSettings { file: string; }

export default function RequestLngObject(props: requestSettings): Promise<any> {
    return new Promise((resolve) => {
        Request(props.file).then((data) => {
            resolve(data || undefined);
        });
    });
}
