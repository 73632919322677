import React from "react";
import requestConfigObj from "../../../index";
import RequestLngObject from "../../Modules/Loader/requestLngObject";
import useWindowDimensions from "../../Modules/Other/viewport";
import Modal from "../../Modules/Interfaces/swipeable-modal";

export interface ServiceProps {
    title: string; content: string; icon: string
}

const ServicesBuilder: React.FC<{ prop: string }> = ({ prop }) => {
    const [objects, setObjects] = React.useState<Array<ServiceProps>>([]);
    const { width, threshold_md } = useWindowDimensions();
    const [selected, setSelected] = React.useState(-1);
    
    React.useEffect(() => {
        RequestLngObject({ file: requestConfigObj.params.file }).then((LngObject: any) => {
            let objects: Array<ServiceProps> = [];
            [...JSON.stringify(LngObject).matchAll(new RegExp(`${prop}(.*?)${'"'}`, "g"))].forEach(match => {
                objects.push(LngObject[match[0].replace('"', '')]);
            });

            setObjects(objects);
        });
    }, [prop]);

    return (
        <>
            {objects.map((_: any, i: any) => {
                if (((width >= threshold_md) && (i === selected)) && document.body.classList.contains(`modal-overflow-hidden`)) {
                    document.body.classList.remove(`modal-overflow-hidden`);
                }

                const item_info = <>
                <h1 className="text-[1.5rem] font-custom_bold text-skin-h_dark">{objects[i].title}</h1>
                <ul className="flex flex-col gap-2 text-skin-p_dark">
                    {objects[i].content.split(",").map((item: string) => (
                        <li key={item} className="text-[1.35rem]"> {item} </li>
                    ))}
                </ul> </>

                return (
                    <div className="flex flex-col gap-16" key={objects[i].title}>
                        <div className={`${(objects[i].icon === "money" ? "bg-money"
                            : (objects[i].icon === "design" ? "bg-design"
                                : (objects[i].icon === "code" ? "bg-code"
                                    : (objects[i].icon === "manage" ? "bg-manage" : ""))))} 
                        w-[3.75rem] h-[3.75rem] md:w-[6.5rem] md:h-[6.5rem] png_icon relative  z-10`}
                        onClick={() => { setSelected(width < threshold_md ? i : selected); }}> </div>
                        <div className="hidden md:flex flex-col gap-6">
                            {item_info}
                        </div>
                        {((width < threshold_md) && (i === selected)) && (
                            <Modal modal_state_function={() => { setSelected(-1); }}
                                down_swipe_limiter={2}
                                track_mouse={true}
                                small_touch_surface={true}
                                small_touch_surface_container_style={"bg-skin-modal_bg pop_up w-full self-center rounded-3xl rounded-b-none cursor-default p-5 pt-0"}
                                wrapper_classes={"fixed w-full z-[100] left-1/2 bottom-0 -translate-x-1/2"}>
                                <div className="md:absolute h-auto bg-skin-modal_bg pop_up w-full rounded-3xl rounded-b-none cursor-default py-5">
                                    {item_info}
                                </div>
                            </Modal>
                        )}
                    </div>
                );
            })}
        </>
    );
}

export default ServicesBuilder