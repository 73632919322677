import { useState, useEffect } from 'react';

const threshold_xs = 450;
const threshold_sm = 640;
const threshold_md = 768;
const threshold_lg = 1024;
const threshold_xl = 1280;
const threshold_2xl = 1536;
const threshold_3xl = 1920;
const threshold_4xl = 2560;

function getWindowDimensions() {
    const { innerWidth: width, innerHeight: height } = window;
    return { width, height, threshold_xs, threshold_sm, threshold_md, threshold_lg, threshold_xl, threshold_2xl, threshold_3xl, threshold_4xl };
}

export default function useWindowDimensions() {
    const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());

    useEffect(() => {
        function handleResize() { setWindowDimensions(getWindowDimensions()); }
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    return windowDimensions;
}