export interface RequestConfigParams {
    file: string;
    LngObj?: any;
}

export class RequestConfig {
    public params: RequestConfigParams;
    constructor(params: RequestConfigParams) {
        this.params = params;
    }
}
