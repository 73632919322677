import Button from "../../Modules/Interfaces/button";
import Reviews from "./reviews";

const About = () => {
    return (
        <section data-main-section="About us">

            <div className="4xl:px-[25vw] 3xl:px-[11vw] 2xl:px-[9vw] xl:px-20 px-7
            pb-28 md:pb-40 xl:pt-20 flex flex-row justify-between w-full">

                <div className="flex flex-col gap-7 w-full xl:w-[36%] text-skin-p_dark">
                    <p data-tag="about_heading_1" className="font-custom_bold text-2xl md:text-3xl"> </p>
                    <p data-tag="about_paragraph_1" className="font-custom_regular text-[1.3rem] md:text-[165%]"> </p>
                    <div className="w-full flex justify-center align-middle md:justify-start md:align-baseline">
                        <Button props={{
                            href: "contact",
                            data_tag: 'about_action_button',
                            bg_color: 'bg-skin-secondary_button_bg_color',
                            text_styling: 'text-skin-secondary_button_text_color text text-md md:text-xl',
                        }} />
                    </div>
                </div>

                <div className="w-[55.5%] h-full hidden xl:flex flex-row justify-center align-middle">
                    <Reviews />
                </div>
            </div>

        </section>
    );
}

export default About;