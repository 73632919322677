import Button from "../../Modules/Interfaces/button";

import "../../../index.css"
import PackageModal from "./Packages Modal/package_modal";

export interface PackageProps {
    title?: string,
    text?: string,
    icon?: string,
    package_options?: string,
    modal_state_function?: Function,
    modal_state?: boolean,
    modal_element_setter_function?: Function,
    modal_ordered_function?: Function,
    modal_ordered?: boolean,
}

export default function Package({ props }: { props: PackageProps }) {
    const icon = (props.icon === "standard" ? "bg-standard"
        : (props.icon === "premium" ? "bg-premium"
            : (props.icon === "deluxe" ? "bg-deluxe"
                : (props.icon === "plus" ? "bg-plus" : ""))));

    return (
        <>
            <div className="relative flex justify-center align-top">
                <div className={`${icon} w-[4rem] h-[4rem] png_icon absolute left-[50%] -translate-x-1/2 -translate-y-[50%]`}>
                </div>
            </div>

            <div className="h-full w-full flex flex-col gap-20 lg:gap-[4.8rem] md:gap-[4.8rem] xl:gap-20 justify-start align-middle pt-20 text-skin-h_dark">
                <div className="flex flex-col gap-2">
                    <div className="flex justify-center align-middle font-custom_bold text-[2.5rem] lg:text-[2.35rem] md:text-[2.35rem] xl:text-[2.5rem]"> {(props.title ? props.title : "")} </div>
                    <div className=" text-[1.5rem] md:text-[1.5rem] lg:text-[1.25rem] xl:text-[1.5rem] w-[200%] self-center"> {(props.text ? props.text : "")} </div>
                </div>

                <div className="w-full flex justify-center align-middle md:justify-start md:align-baseline">
                    <div className="flex justify-center align-middle self-center w-full">
                        <Button props={{
                            data_tag: 'package_button',
                            bg_color: 'bg-skin-secondary_button_bg_color',
                            text_styling: 'text-skin-secondary_button_text_color text text-xl',
                            onclick: () => {
                                if (props.modal_state_function !== undefined) { props.modal_state_function(true); }
                                if (props.modal_element_setter_function !== undefined) {
                                    props.modal_element_setter_function(<PackageModal props={{
                                        title: props.title,
                                        icon: icon,
                                        package_options: props.package_options,
                                        modal_state_function: props.modal_state_function,
                                        modal_ordered_function: props.modal_ordered_function,
                                        modal_ordered: props.modal_ordered
                                    }} />);
                                }
                            }
                        }} />
                    </div>
                </div>
            </div>

        </>
    );
}