const MailSVG = (props: any) => (
    <svg
        width={16}
        height={14}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <g className="fill-skin-nav_primary_dark  transition-colors duration-500">
            <path d="M0 4.462V11a2.286 2.286 0 0 0 2.286 2.286h11.428A2.286 2.286 0 0 0 16 11V4.462L9.198 8.648a2.286 2.286 0 0 1-2.396 0L0 4.462Z" />
            <path d="M16 3.12V3A2.286 2.286 0 0 0 13.714.714H2.286A2.286 2.286 0 0 0 0 3v.12l7.401 4.555c.367.226.83.226 1.198 0L16 3.12Z" />
        </g>
    </svg>
)

export default MailSVG
