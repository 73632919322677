import React, { useState } from "react";
import requestConfigObj from "../../../index";
import RequestLngObject from "../../Modules/Loader/requestLngObject";
import useWindowDimensions from "../../Modules/Other/viewport";
import PackagesBuilderWrapper from "./package_wrapper";
import { SwiperSlide } from "swiper/react";
import Package, { PackageProps } from "./package";
import Modal from "../../Modules/Interfaces/swipeable-modal"

const Packages = () => {
    const prop = "packages_package";
    const [objects, setObjects] = React.useState<Array<PackageProps>>([]);
    const { width, threshold_lg } = useWindowDimensions();

    React.useEffect(() => {
        RequestLngObject({ file: requestConfigObj.params.file }).then((LngObject: any) => {
            let objects: Array<PackageProps> = [];
            [...JSON.stringify(LngObject).matchAll(new RegExp(`${prop}(.*?)${'"'}`, "g"))].forEach(match => {
                objects.push(LngObject[match[0].replace('"', '')]);
            });
            setObjects(objects);
        });
    }, [prop]);

    const [openModal, setOpenModal] = useState(false);
    const [orderedModal, setOrderedModal] = useState(false);
    const [modalElement, setModalElement] = useState(<> </>);

    return (
        <section data-main-section="Packages">

            {openModal && (
                <Modal
                    modal_state_function={setOpenModal}
                    down_swipe_limiter={.5}
                    up_swipe_limiter={7}
                    custom_height={100}
                    wrapper_classes={"fixed w-full z-[100] left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2"}>
                    {modalElement}
                </Modal>
            )}

            <div className="4xl:px-[25vw] 3xl:px-[11vw] 2xl:px-[9vw] xl:px-20 px-7
            flex flex-col w-full justify-center gap-14 md:gap-32 pt-14 pb-28 md:pt-24 md:pb-40 text-skin-dark overflow-hidden">

                <div className="flex flex-col gap-7">
                    <h1 data-tag="packages_heading_1" className="font-custom_bold text-2xl md:text-3xl text-skin-h_dark"> </h1>
                    <p data-tag="packages_paragraph_1" className="font-custom_regular text-[1.3rem] md:text-[165%] text-skin-p_dark" > </p>
                </div>

                <PackagesBuilderWrapper
                    style_standard={"flex flex-row justify-between"}
                    style_swiper={"w-full h-full pb-10"}>
                    {objects.map((_: any, i: any) => {
                        const packageProps = {
                            title: objects[i].title, text: objects[i].text, icon: objects[i].icon, package_options: objects[i].package_options,
                            modal_state_function: setOpenModal, modal_state: openModal, modal_element_setter_function: setModalElement,
                            modal_ordered_function: setOrderedModal, modal_ordered: orderedModal
                        };
                        const style = "w-[16rem] h-[22rem] lg:w-[14.5rem] lg:h-[20rem] xl:w-[16rem] xl:h-[22rem] " +
                            "rounded-3xl lg:rounded-2xl xl:rounded-3xl " +
                            "bg-skin-modal_bg review_shadow px-16 mt-9 flex flex-col justify-center align-middle text-center  overflow-visible";
                        return width < threshold_lg ? (<SwiperSlide key={`${objects[i].title}`} className={`${style}`}> <Package props={packageProps} /> </SwiperSlide>)
                            : (<div key={`${objects[i].text}`} className={`${style}`}> <Package props={packageProps} /> </div>);
                    })}
                </PackagesBuilderWrapper>

            </div>
        </section>
    );
};

export default Packages;

