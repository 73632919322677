import React from "react";
import requestConfigObj from "../../../index";
import { ValidateMail } from "../Contact/email_validate";
import Button from "../Interfaces/button";
import RequestLngObject from "../Loader/requestLngObject";
import SendMail from "../Contact/contact";

interface contactFormInterface { toggle_function: Function }
interface onChangeHandlerInterface { destination: string, classToggle: string, case_1: boolean, case_2: boolean, case_1_toggle: boolean, case_2_toggle: boolean }

const onChangeHandler = ({ props }: { props: onChangeHandlerInterface }) => {
    const input = document.querySelector(props.destination);
    if (props.case_1) { input?.classList.toggle(props.classToggle, props.case_1_toggle); }
    else if (props.case_2) { input?.classList.toggle(props.classToggle, props.case_2_toggle); }
}

export const ContactForm = ({ props }: { props: contactFormInterface }) => {
    const [mail_input_placeholder, setMailPlaceholder] = React.useState("Email: "), [name_input_placeholder, setNamePlaceholder] = React.useState("Name: "),
        [subject_input_placeholder, setSubjectPlaceholder] = React.useState("Subject: "), [message_input_placeholder, setMessagePlaceholder] = React.useState("Message: "), [contact_form_message, setContactFormMessage] = React.useState(""),
        [contact_form_submit_button_default, setSendButtonDefault] = React.useState("contact_form_submit_button_send");

    RequestLngObject({ file: requestConfigObj.params.file }).then((LngObject) => {
        setMailPlaceholder(LngObject["mail_input_placeholder"]); setNamePlaceholder(LngObject["name_input_placeholder"]);
        setSubjectPlaceholder(LngObject["subject_input_placeholder"]); setMessagePlaceholder(LngObject["message_input_placeholder"]); setContactFormMessage(LngObject["contact_form_message"]);
    });

    const min_name_char_count = 3, min_subject_char_count = 4;
    const input_style = "bg-skin-input_field_mail_bg text-skin-input_field_mail_text placeholder-skin-input_field_mail_text w-full pt-2 md:pt-3 pb-2 px-5 rounded-lg flex flex-row align-middle text-sm md:text-lg lg:text-lg border border-border_color font-custom_regular text-skin-input_text_light ";

    return (
        <div className="w-full h-full rounded-[inherit] p-5">
            <h1 className="text-2xl font-custom_bold text-skin-h_dark pb-4"> {contact_form_message} </h1>
            <form data-contact-form onSubmit={(e) => { e.preventDefault(); }} className="flex flex-col gap-3 md:gap-7">
                <input type="text" data-contact-form-input="name" placeholder={name_input_placeholder}
                    className={input_style} onChange={() => {
                        const value = (document.querySelector("[data-contact-form-input='name']") as HTMLInputElement | null)?.value || "";
                        onChangeHandler({ props: { destination: "[data-contact-form-input='name']", classToggle: "wrong-field-mail", case_1: (value.length < min_name_char_count), case_2: (value.length > 0), case_1_toggle: true, case_2_toggle: false } });
                    }} />

                <input type="email" data-contact-form-input="mail" placeholder={mail_input_placeholder}
                    className={input_style} onChange={() => {
                        const value = (document.querySelector("[data-contact-form-input='mail']") as HTMLInputElement | null)?.value || "";
                        onChangeHandler({ props: { destination: "[data-contact-form-input='mail']", classToggle: "wrong-field-mail", case_1: (value.length > 0), case_2: (true), case_1_toggle: !ValidateMail(value), case_2_toggle: false } });
                    }} />

                <input type="text" data-contact-form-input="subject" placeholder={subject_input_placeholder}
                    className={input_style} onChange={() => {
                        const value = (document.querySelector("[data-contact-form-input='subject']") as HTMLInputElement | null)?.value || "";
                        onChangeHandler({ props: { destination: "[data-contact-form-input='subject']", classToggle: "wrong-field-mail", case_1: (value.length < min_subject_char_count), case_2: (value.length > 0), case_1_toggle: true, case_2_toggle: false } });
                    }} />

                <textarea typeof="text" data-contact-form-input="message" placeholder={message_input_placeholder}
                    className={input_style + " min-h-[7rem] max-h-[12rem] scrollbar-thumb-[#1c1c1c] scrollbar-track-transparent scrollbar-thin overflow-auto"}></textarea>

                <div className="w-full flex justify-center align-middle">
                    <Button props={{
                        data_tag: contact_form_submit_button_default,
                        bg_color: 'bg-skin-secondary_button_bg_color',
                        text_styling: 'text-skin-secondary_button_text_color text-sm md:text-xl',
                        onclick: () => {
                            let passed = true; document.querySelectorAll("[data-contact-form-input]")?.forEach(e => {
                                if (e.classList.contains("wrong-field-mail") || ((e as HTMLInputElement | null)?.value || "").replace(/\s/g, '').toString().length === 0) {
                                    passed = false; e.classList.toggle("anim-wrong", true); setTimeout(() => { e.classList.toggle("anim-wrong", false); }, 350); return;
                                }
                            }); if (passed) {
                                SendMail({
                                    props: {
                                        name: (document.querySelector("[data-contact-form-input='name']") as HTMLInputElement | null)?.value || "",
                                        mail: (document.querySelector("[data-contact-form-input='mail']") as HTMLInputElement | null)?.value || "",
                                        subject: (document.querySelector("[data-contact-form-input='subject']") as HTMLInputElement | null)?.value || "",
                                        message: (document.querySelector("[data-contact-form-input='message']") as HTMLInputElement | null)?.value || ""
                                    }
                                });
                                setSendButtonDefault("contact_form_submit_button_sent");
                                setTimeout(() => {
                                    setSendButtonDefault("contact_form_submit_button_send");
                                    const form = document.querySelector("[data-contact-form]");
                                    if (form instanceof HTMLFormElement) { form.reset(); }
                                    setTimeout(() => { props.toggle_function(); document.body.classList.remove(`modal-overflow-hidden`); }, 2000);
                                }, 2000);
                            }
                        }
                    }}
                    />
                </div>
            </form>
        </div>
    );
}