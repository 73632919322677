interface ValidateProps {
    validation_function: Function,
    target: HTMLInputElement | null
}

export const ValidateMail = (email: string) => {
    let regex = /^(([^<>()\]\\.,;:\s@"]+(\.[^<>()\]\\.,;:\s@"]+)*)|(".+"))@(([0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (email.toLowerCase().match(regex)) { return true; } else { return false; }
};

export default function ValidationBundler({ props }: { props: ValidateProps }) {
    if (props.target !== null) {
        const result = !props.validation_function(props.target?.value);
        props.target.classList.toggle("wrong-field-mail", result);
        props.target.classList.toggle("anim-wrong", result);
        setTimeout(() => { props.target?.classList.toggle("anim-wrong", false); }, 350);
        return !result;
    }
}

