const PhoneSVG = (props: any) => (
    <svg
        width={15}
        height={16}
        className="fill-skin-nav_primary_dark transition-colors duration-500"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <path
            fillRule="nonzero"
            clipRule="nonzero"
            d="M0 2.643C0 1.459.96.5 2.143.5h.98c.614 0 1.15.418 1.299 1.014l.79 3.16a1.34 1.34 0 0 1-.496 1.396l-.924.693c-.096.072-.117.177-.09.251a8.061 8.061 0 0 0 4.784 4.784c.074.027.179.006.25-.09l.694-.924a1.34 1.34 0 0 1 1.396-.496l3.16.79a1.34 1.34 0 0 1 1.014 1.3v.98c0 1.183-.96 2.142-2.143 2.142H11.25C5.037 15.5 0 10.463 0 4.25V2.643Z"
            className="fill-skin-nav_primary_dark transition-colors duration-500"
        />
    </svg>
)

export default PhoneSVG
