import Button from "../../../Modules/Interfaces/button";
import SendMail from "../../../Modules/Contact/contact";
import ValidationBundler, { ValidateMail } from "../../../Modules/Contact/email_validate";
import { PackageProps } from "../package";
import ModalItems from "./package_modal_items";
import RequestLngObject from "../../../Modules/Loader/requestLngObject";
import requestConfigObj from "../../../../index";
import React from "react";

function EditButtonHandler() {
    document.querySelectorAll(".package_add_on_item")?.forEach(element => {
        if (element.classList?.contains("item_inactive")) { element.classList?.toggle("hidden"); }
        element.classList?.toggle("editing_item");
    });
}

function OrderedSuccessfullyHandler() {
    document.querySelector(".package_modal")?.classList.toggle("modal_sent", true);
    document.querySelector(".ordered_message")?.classList.toggle("hidden", false);
    document.querySelector(".ordered_message")?.classList.toggle("flex", true);
}

function SendButtonHandler({ props }: { props: PackageProps }) {
    if (ValidationBundler({ props: { target: document.querySelector("[data-modal-form-mail]") as HTMLInputElement | null, validation_function: ValidateMail } }) === true) {
        RequestLngObject({ file: requestConfigObj.params.file }).then((LngObject) => {      
            let message = `${LngObject["contact_order_default_message_start"]}<br/> <h3> <b> ${props.title} </b> </h3>`;
            document.querySelectorAll(".package_item:not(.item_inactive)").forEach(e => {
                message += `• ` + (e as HTMLElement).innerText + '<br/>';
            });
            if (props.modal_ordered_function !== undefined) { props.modal_ordered_function(true); } OrderedSuccessfullyHandler();
            setTimeout(() => { if (props.modal_state_function !== undefined) { props.modal_state_function(false); document.body.classList.remove(`modal-overflow-hidden`); } }, 5000);
            SendMail({ props: { message: message, subject: ` Package request: ${props.title}`, mail: (document.querySelector("[data-modal-form-mail]") as HTMLInputElement | null)?.value || "" } });
        });
    }
}

export default function PackageModal({ props }: { props: PackageProps }) {
    const [orderedMessagePrimary, setOrderedMessagePrimary] = React.useState("");
    const [orderedMessageSecondary, setOrderedMessageSecondary] = React.useState("");
    const [mail_input_placeholder, setPlaceholder] = React.useState("");

    RequestLngObject({ file: requestConfigObj.params.file }).then((LngObject) => {
        setOrderedMessagePrimary(LngObject["modal_ordered_message_primary"]);
        setOrderedMessageSecondary(LngObject["modal_ordered_message_secondary"]);
        setPlaceholder(LngObject["modal_order_mail_input_placeholder"]);
    });

    if (props.modal_ordered === true) { OrderedSuccessfullyHandler(); }

    return (
        <div className="open_modal">
            <div className="absolute top-[50%] left-[50%] -translate-x-1/2 -translate-y-[50%] w-full md:w-[33.125rem] px-2 md:px-0">

                {/* ordered message */}
                <div className="z-[150] w-[95%] absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 h-full flex-col gap-3 md:gap-5 justify-center align-middle text-center ordered_message hidden">
                    <div className="w-full flex flex-col justify-center align-middle">
                        <div className="w-[40px] h-[40px] bg-skin-yes_green self-center rounded-full 
                    flex flex-col justify-center align-middle text-center
                    font-custom_bold text-skin-light text-xl md:text-2xl md:pt-[.25rem]"> ✓ </div>
                    </div>

                    <div className="w-full flex flex-col justify-center align-middle">
                        <div className="text-skin-h_dark font-custom_bold text-lg md:text-xl"> {orderedMessagePrimary} </div>
                        <div className="text-skin-h_dark font-custom_regular text-md md:text-lg"> {orderedMessageSecondary} </div>
                    </div>
                </div>

                {/* top icon */}
                <div className="relative flex justify-center align-top z-40">
                    <div className={`${props.icon} w-[3rem] h-[3rem] md:w-[4rem] md:h-[4rem] png_icon absolute left-[50%] -translate-x-1/2 -translate-y-[50%]`}>
                    </div>
                </div>

                {/* main body */}
                <div className="bg-skin-modal_bg self-center rounded-3xl modal_shadow p-4 md:p-7 package_modal">
                    <div className="w-full h-full flex flex-col justify-between gap-5 md:gap-14 pt-7">

                        {/* items */}
                        <div className="relative modal_bg modal_shadow w-full md:min-h-[10rem] p-0 pb-2 md:p-3 rounded-xl
                        flex flex-col justify-center align-middle">
                            <div className="max-h-[20rem] ml:max-h-[6rem] md:max-h-[25rem] overflow-y-auto overflow-x-hidden
                             scrollbar-thumb-[#1c1c1c] scrollbar-track-transparent scrollbar-thin">
                                <ModalItems props={{ package_options: props.package_options }} />
                            </div>

                            <div className="flex flex-row-reverse">
                                <button className="bg-edit_icon w-[25px] h-[25px] cursor-pointer packages_edit_button" onClick={EditButtonHandler}>
                                </button>
                            </div>
                        </div>

                        {/* mail form */}
                        <form onSubmit={(e) => { e.preventDefault(); }} className="flex flex-col gap-3 md:gap-7">
                            <input type="email" data-modal-form-mail placeholder={mail_input_placeholder}
                                className="bg-skin-input_field_mail_bg text-skin-input_field_mail_text placeholder-skin-input_field_mail_text 
                                w-full pt-2 md:pt-3 pb-2 px-5 rounded-lg flex flex-row align-middle text-sm md:text-lg lg:text-lg modal_shadow
                                font-custom_regular text-skin-input_text_light"
                                onChange={() => {
                                    const mailInput = document.querySelector("[data-modal-form-mail]");
                                    const value = (mailInput as HTMLInputElement | null)?.value || "";
                                    if (value.length > 0) { mailInput?.classList.toggle("wrong-field-mail", !ValidateMail(value)); }
                                    else { mailInput?.classList.toggle("wrong-field-mail", false); }
                                }} />
                            <div className="w-full flex justify-center align-middle">
                                <Button props={{
                                    data_tag: 'package_modal_button',
                                    bg_color: 'bg-skin-secondary_button_bg_color',
                                    text_styling: 'text-skin-secondary_button_text_color text-sm md:text-xl',
                                    onclick: () => {
                                        SendButtonHandler({
                                            props: {
                                                title: props.title,
                                                modal_state_function: props.modal_state_function,
                                                modal_ordered_function: props.modal_ordered_function
                                            }
                                        })
                                    }
                                }}
                                />
                            </div>
                        </form>
                    </div>

                </div>

            </div>
        </div>
    );
}
