const TickSVG = (props: any) => (
    <svg
        viewBox="0 0 39 39"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <g filter="url(#a)">
            <rect x={3} y={2.5} width={32} height={32} rx={6.25} className="fill-skin-light" />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M24.277 13.584a.5.5 0 0 1 .139.693l-6 9a.5.5 0 0 1-.77.077l-4-4a.5.5 0 0 1 .708-.707l3.568 3.568 5.662-8.492a.5.5 0 0 1 .693-.139Z"
                className="fill-skin-base"
            />
        </g>
        <defs>
            <filter
                id="a"
                x={0}
                y={0.5}
                width={38}
                height={38}
                filterUnits="userSpaceOnUse"
                colorInterpolationFilters="sRGB"
            >
                <feFlood floodOpacity={0} result="BackgroundImageFix" />
                <feColorMatrix
                    in="SourceAlpha"
                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                    result="hardAlpha"
                />
                <feMorphology
                    radius={1}
                    operator="dilate"
                    in="SourceAlpha"
                    result="effect1_dropShadow_47_272"
                />
                <feOffset dy={1} />
                <feGaussianBlur stdDeviation={1} />
                <feComposite in2="hardAlpha" operator="out" />
                <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.5 0" />
                <feBlend in2="BackgroundImageFix" result="effect1_dropShadow_47_272" />
                <feBlend
                    in="SourceGraphic"
                    in2="effect1_dropShadow_47_272"
                    result="shape"
                />
            </filter>
        </defs>
    </svg>
)

export default TickSVG
