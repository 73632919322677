import Button from "../../Modules/Interfaces/button";
import HeroSVG from "../../Modules/SVGs/sections/hero_section/heroSVG";
import LaptopSVG from "../../Modules/SVGs/sections/hero_section/laptopSVG";
import PhoneBigSVG from "../../Modules/SVGs/sections/hero_section/phoneBigSVG";

const Hero = () => {
    return (
        <section id="hero">

            <div className="4xl:px-[25vw] 3xl:px-[11vw] 2xl:px-[9vw] xl:px-20 px-7 
            absolute z-30 top-[9rem] md:top-[9rem] xl:top-[8rem] 2xl:top-[8re] 3xl:top-[10rem]
            lg:top-[10rem] w-full flex flex-row p-7 select-none">

                <div className="md:w-1/2 flex flex-col gap-8">
                    <h1 data-tag="hero_heading_1" className="bg-skin-base md:bg-transparent pt-2 mt-[-1rem] text-skin-light md:leading-tight text-3xl md:text-[450%] xl:text-[450%] 2xl:text-[500%] font-custom_bold"> </h1>
                    <p data-tag="hero_paragraph_1" className="text-2xl md:text-[140%] xl:text-[150%] 2xl:text-[160%] md:w-2/3 leading-normal font-custom_thin text-skin-light"> </p>
                    <div className="w-full flex justify-start align-baseline">
                        <Button props={{ href: "contact", data_tag: 'hero_action_button' }} />
                    </div>
                </div>

            </div>

            <div className="relative overflow-hidden">

                <div className="absolute hidden md:flex w-full h-[37.85rem] bg-skin-hero_fill top-0 transition-colors duration-500"> </div>
                <div className="md:mt-[10rem] -mt-[11rem]"> <HeroSVG /> </div>
                <div className="hidden md:flex absolute w-[165rem] h-[165rem] -mt-[47rem] left-[68vw] right-0"> <LaptopSVG /> </div>
                <div className="hidden md:flex absolute w-[77rem] h-[77rem] -mt-[41.5rem] left-[62vw] right-0"> <PhoneBigSVG /> </div>

            </div>

        </section>
    );
}

export default Hero;