const KNVLogo = (props: any) => (
    <svg
        viewBox="0 0 1000 1000"
        xmlns="http://www.w3.org/2000/svg"
        xmlSpace="preserve"
        style={{
            fillRule: "evenodd",
            clipRule: "evenodd",
            strokeLinejoin: "round",
            strokeMiterlimit: 2,
        }}
        {...props}
    >
        <path
            d="M242.637 680.979c0 10.694 8.669 19.363 19.363 19.363h22.918a19.362 19.362 0 0 0 19.363-19.363V558.562h3.082c8.63 0 14.178 6.78 15.411 15.411l16.07 109.81c1.391 9.51 9.548 16.559 19.159 16.559h22.505a19.363 19.363 0 0 0 19.159-22.166 49751606.2 49751606.2 0 0 0-15.249-104.203c-3.082-20.343-17.26-35.137-36.37-40.069 28.356-4.315 34.52-22.808 36.37-52.397l10.927-161.177a19.364 19.364 0 0 0-19.319-20.672h-22.788c-10.202 0-18.654 7.915-19.322 18.096l-11.142 169.917c-.616 8.63-6.781 15.411-15.411 15.411h-3.082V319.021a19.36 19.36 0 0 0-19.363-19.363H262c-10.694 0-19.363 8.669-19.363 19.363v361.958Z"
            className="fill-skin-nav_primary_dark transition-colors duration-500"
        />
        <path
            d="M532.363 294.726c-25.274 0-45 17.877-50.548 34.521l-2.235-13.41c-1.556-9.336-9.634-16.179-19.1-16.179h-19.713c-10.694 0-19.363 8.669-19.363 19.363v361.958c0 10.694 8.669 19.363 19.363 19.363h22.918a19.363 19.363 0 0 0 19.363-19.363V364.384c0-8.631 6.781-15.411 15.411-15.411 8.63 0 15.411 6.78 15.411 15.411V868.96a19.363 19.363 0 0 1-19.363 19.364H142.343c-10.694 0-19.363-8.67-19.363-19.363V131.039a19.36 19.36 0 0 1 5.672-13.691 19.36 19.36 0 0 1 13.691-5.672h715.314a19.36 19.36 0 0 1 13.691 5.672 19.36 19.36 0 0 1 5.672 13.691v737.922c0 10.693-8.669 19.363-19.363 19.363h-62.986a19.363 19.363 0 0 0-19.364 19.363v22.95A19.363 19.363 0 0 0 794.671 950h122.867a19.362 19.362 0 0 0 19.363-19.363V69.363c0-10.694-8.669-19.363-19.363-19.363H82.462c-10.694 0-19.363 8.669-19.363 19.363v861.274A19.363 19.363 0 0 0 82.462 950h473.689a19.362 19.362 0 0 0 19.363-19.363v-592.76c0-24.041-13.562-43.151-43.151-43.151Z"
            className="fill-skin-nav_primary_dark  transition-colors duration-500"
        />
        <path
            d="M671.062 644.863 653.55 317.985c-.55-10.277-9.044-18.327-19.335-18.327h-22.057a19.365 19.365 0 0 0-19.282 21.143l33.412 361.958c.919 9.962 9.277 17.583 19.281 17.583h57.15c10.004 0 18.361-7.621 19.281-17.583l33.411-361.958a19.364 19.364 0 0 0-19.281-21.143h-22.057c-10.292 0-18.785 8.05-19.336 18.327l-17.511 326.878h-6.164Z"
            className="fill-skin-nav_primary_dark  transition-colors duration-500"
        />
        <path
            d="M719.693 907.47c0-10.694-8.669-19.364-19.363-19.364h-52.372c-10.694 0-19.363 8.67-19.363 19.364v23.167c0 10.694 8.669 19.363 19.363 19.363h52.372c10.694 0 19.363-8.669 19.363-19.363V907.47Z"
            className="fill-skin-nav_primary_dark  transition-colors duration-500"
        />
    </svg>
);

export default KNVLogo;