import { InlineWidget } from "react-calendly";
import useWindowDimensions from "../../Modules/Other/viewport";

import RequestLngObject from "../../Modules/Loader/requestLngObject";
import requestConfigObj from "../../../index";

import '../../../index.css';
import React from "react";
import TickSVG from "../../Modules/SVGs/icons/tick";
import { ThemeContext } from "../../Modules/Other/context";

const Contact = () => {
    const prop = "contact_info_more_item_";
    const { width, threshold_xl } = useWindowDimensions();

    const CalendlyProps: React.CSSProperties = {
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
        width: '100%',
        padding: '.5rem',
        overflowX: 'hidden',
        overflowY: 'hidden',
        borderRadius: '15px',
        background: 'white'
    }

    const [CalendlyURL, setCalendlyURL] = React.useState("");
    RequestLngObject({ file: requestConfigObj.params.file }).then((LngObject) => { setCalendlyURL(LngObject["calendly_url"]); });

    return (
        <ThemeContext.Consumer>
            {(data) => {
                return (
                    <section data-main-section="Contact" className="md:-mt-28">
                        <div className="bg-skin-base 2xl:bg-transparent 4xl:bg-skin-base
            4xl:px-[25vw] 3xl:px-[11vw] 2xl:px-[9vw] xl:px-20 px-7 h-[70rem] xl:h-[68rem] 4xl:h-[75rem]
            flex flex-col-reverse xl:flex-row w-full justify-between gap-14 xl:gap-44 xl:pt-24 md:mt-[-3rem] pb-12 xl:pb-40 text-skin-light overflow-hidden pt-20">
                            {(width < threshold_xl) && (<ContactExtraInfo prop={prop} />)}

                            <div className="my-0 mx-0 px-0 py-0 xl:w-[42%] h-full flex flex-col justify-start align-top relative png_icon inline_widget">
                                <InlineWidget url={CalendlyURL} styles={CalendlyProps} />
                            </div>

                            <div className=" xl:w-[42%] flex flex-col gap-6 xl:gap-12">
                                <h1 data-tag="contact_heading_1" className="text-skin-light xl:leading-tight text-3xl xl:text-4xl font-custom_bold"> </h1>
                                <p data-tag="contact_paragraph_1" className="text-xl xl:text-[1.5rem] font-custom_regular text-skin-light"> </p>
                                {(width > threshold_xl) && (<ContactExtraInfo prop={prop} />)}
                            </div>
                        </div>
                    </section>
                )
            }}
        </ThemeContext.Consumer>
    );
}

interface ContactInfoMoreItemProps {
    content: string
}

const ContactExtraInfo: React.FC<{ prop: string }> = ({ prop }) => {
    const [objects, setObjects] = React.useState<Array<ContactInfoMoreItemProps>>([]);

    React.useEffect(() => {
        RequestLngObject({ file: requestConfigObj.params.file }).then((LngObject: any) => {
            let objects: Array<ContactInfoMoreItemProps> = [];
            [...JSON.stringify(LngObject).matchAll(new RegExp(`${prop}(.*?)${'"'}`, "g"))].forEach(match => {
                objects.push(LngObject[match[0].replace('"', '')]);
            });

            setObjects(objects);
        });
    }, [prop]);

    return (
        <div className="flex flex-col gap-6 xl:gap-8">
            <h2 data-tag="contact_heading_2" className="text-skin-lightfont-custom_bold text-xl xl:text-3xl"> </h2>
            <div className="flex flex-col gap-6 xl:gap-8 font-custom_regular text-skin-light text-[1.1rem] xl:text-[1.5rem]">
                {objects.map((_: any, i: any) => {
                    return (
                        <div key={"contact_info_more_item" + i} className="flex flex-row align-middle gap-9">
                            <div className="w-[52px] h-[52px] flex flex-col justify-center align-middle">
                                <TickSVG />
                            </div>
                            <p> {objects[i].content} </p>
                        </div>
                    )
                })
                }
            </div>
        </div>
    );
}

export default Contact;