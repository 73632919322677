import RequestLngObject from "./requestLngObject";

interface requestSettings {
    file: string;
    attribute: string;
}

export default function XMLHttpRequest({ props }: { props: requestSettings }): void {
    RequestLngObject({ file: props.file }).then((LngObject) => {
        var allDom = document.getElementsByTagName("*");

        for (var i = 0; i < allDom.length; i++) {
            var elem = allDom[i];
            var key = elem.getAttribute(props.attribute);
            if (key !== null) { elem.innerHTML = LngObject[key]; }
        }
    });
}
