import { useState } from "react";
import classNames from "classnames";

interface SwitchProps {
    toggle: Function
    width?: string,
    height?: string,
    state: boolean
}

const Switch = ({ props }: { props: SwitchProps }) => {
    const [isSelected, setIsSelected] = useState(props.state);

    return (
        <>
            <div className={classNames(`flex align-middle ${props.height !== undefined ? props.height : "h-full"} ${props.width !== undefined ? props.width : "w-full"} 
              bg-skin-contrast rounded-full shadow-sm shadow-[rgba(0,0,0,.4)] relative transition-all duration-500`, {
                "bg-skin-yes_green": isSelected
            })}>
                <span className={classNames("absolute h-[100%] w-[50%] bg-skin-contrast_alt rounded-full transition-all duration-500 shadow-sm cursor-pointer", {
                    "ml-[50%]": isSelected
                })} onClick={() => { props.toggle(); setIsSelected(!isSelected) }}> </span>
            </div>
        </>
    );
}

export default Switch