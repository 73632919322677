import requestConfigObj from "../../../index";
import RequestLngObject from "../Loader/requestLngObject";
import Email from "./smtp";

interface MailProps {
    name?: string,
    mail: string,
    message: string,
    subject?: string | undefined
}

export default function SendMail({ props }: { props: MailProps }) {
    RequestLngObject({ file: requestConfigObj.params.file }).then((LngObject) => {
        const mail_body = 'email: ' + props.mail + '<br/> message: ' + (props.name !== undefined ? `from ${props.name}: <br/>` : "") + props.message;
        Email.send({
            SecureToken: LngObject["secure_token"],
            To: LngObject["contact_send_to"],
            From: LngObject["contact_send_to"],
            Subject: (props.subject === undefined || props.subject === "" ? LngObject["contact_default_subject"] : props.subject),
            Body: mail_body
        }).then(() => { }).catch((error) => { console.log(error); });
    });
}

