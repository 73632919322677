const SunSVG = (props: any) => (
    <svg
        width={20}
        height={20}
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 20 20"
        {...props}
    >
        <g className="fill-skin-nav_primary_dark transition-colors duration-500">
            <path d="M10 .25a.75.75 0 0 1 .75.75v2.25a.75.75 0 0 1-1.5 0V1A.75.75 0 0 1 10 .25ZM5.5 10a4.5 4.5 0 1 1 9 0 4.5 4.5 0 0 1-9 0ZM16.894 4.166a.75.75 0 0 0-1.06-1.06l-1.591 1.59a.75.75 0 1 0 1.06 1.061l1.591-1.59ZM19.75 10a.75.75 0 0 1-.75.75h-2.25a.75.75 0 0 1 0-1.5H19a.75.75 0 0 1 .75.75ZM15.834 16.894a.75.75 0 0 0 1.06-1.06l-1.59-1.591a.75.75 0 1 0-1.061 1.06l1.59 1.591ZM10 16a.75.75 0 0 1 .75.75V19a.75.75 0 0 1-1.5 0v-2.25A.75.75 0 0 1 10 16ZM5.758 15.303a.75.75 0 0 0-1.061-1.06l-1.591 1.59a.75.75 0 0 0 1.06 1.061l1.591-1.59ZM4 10a.75.75 0 0 1-.75.75H1a.75.75 0 0 1 0-1.5h2.25A.75.75 0 0 1 4 10ZM4.697 5.757a.75.75 0 0 0 1.06-1.06l-1.59-1.591a.75.75 0 0 0-1.061 1.06l1.59 1.591Z" />
        </g>
    </svg>
)

export default SunSVG
