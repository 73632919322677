import React from "react";
import requestConfigObj from "../../../index";
import RequestLngObject from "../Loader/requestLngObject";

import '../../../index';
import Switch from "../Interfaces/switch";
import { ThemeContext } from "./context";

export const ThemeSettings = () => {

    const default_theme = "theme-base", default_scheme = "dark";
    const [theme, setTheme] = React.useState<string>(localStorage.getItem('theme') !== null ? localStorage.getItem('theme') || default_theme : default_theme);
    const [scheme, setScheme] = React.useState<string>(localStorage.getItem('scheme') !== null ? localStorage.getItem('scheme') || default_scheme : default_scheme);
    const [toggleState, setToggleState] = React.useState<boolean>((theme !== `theme-base`));
    React.useEffect(() => { document.documentElement.className = ''; document.documentElement.classList.toggle(theme); }, [theme]);

    const switchTheme = (props: string) => {
        setInStorage(theme !== props ? props : default_theme, 'theme');
        setTheme(theme !== props ? props : default_theme);
        setToggleState((theme !== `theme-base`));
    };
    const switchThemeOn = (props: string) => { setInStorage(props, 'theme'); setTheme(props); }
    const switchScheme = (props: string) => { setInStorage(theme !== props ? props : default_theme, 'scheme'); setScheme(props); };
    const setInStorage = (theme: string, save: string) => { if (typeof localStorage !== 'undefined') { localStorage.setItem(save, theme); } }

    const [option_1, setOption_1] = React.useState<string>(""), [option_description, setOption_description] = React.useState<string>("");

    RequestLngObject({ file: requestConfigObj.params.file }).then((LngObject: any) => {
        setOption_1(LngObject["theme_setting_1"]); setOption_description(LngObject["theme_setting_description"]);
    });

    return (
        <ThemeContext.Consumer>
            {(data) => {
                return (
                    <div className="pop_up w-full h-full md:w-[300px] rounded-3xl md:rounded-2xl rounded-b-none md:rounded-b-2xl cursor-default
                        bg-skin-modal_bg shadow-xl shadow-[rgba(0,0,0,.1)] p-5 pt-0 md:pt-5
                        flex flex-col gap-5">

                        <div className="pop_up flex md:hidden flex-row w-full justify-center align-middle pt-[.5rem]">
                            <div className="w-[2.3rem] h-[.33rem] bg-skin-contrast rounded-full opacity-[.175]"> </div>
                        </div>

                        <div className="flex flex-col gap-0 w-full pb-5 md:pb-0">

                            <div className="flex flex-col gap-5 align-middle mt-5 text-skin-h_dark font-custom_bold text-lg">

                                <div className="flex flex-row justify-between align-middle">
                                    <div className="mt-[.1rem]"> {option_1} </div>
                                    <Switch props={{
                                        state: toggleState,
                                        toggle: () => { switchTheme(`theme-${((data.theme_data.theme === `theme-${scheme}`)) ? "base" : scheme}`) },
                                        width: "w-[50px]", height: "h-[25px]"
                                    }} />
                                </div>

                                <div className="font-custom_regular text-sm text-skin-h_dark opacity-60"> {option_description} </div>

                                <div className="w-full h-[2px] bg-skin-contrast opacity-[.175]"> </div>

                                <ColorSchemeOptionBuilder props={{
                                    theme: data.theme_data.theme || "", scheme: scheme, location: 'theme_color_scheme',
                                    scheme_function: switchScheme, theme_function: switchThemeOn
                                }} />

                            </div>

                        </div>

                    </div>
                );
            }}
        </ThemeContext.Consumer>
    );
}

interface ColorSchemeOptionBuilderProps { theme: string, scheme: string, location: string, scheme_function: Function, theme_function: Function }
interface ColorSchemeProps { label: string, scheme: string, group: string }

const ColorSchemeOptionBuilder = ({ props }: { props: ColorSchemeOptionBuilderProps }) => {
    const [objects, setObjects] = React.useState<Array<ColorSchemeProps>>([]);

    React.useEffect(() => {
        RequestLngObject({ file: requestConfigObj.params.file }).then((LngObject: any) => {
            let objects: Array<ColorSchemeProps> = [];
            [...JSON.stringify(LngObject).matchAll(new RegExp(`${props.location}(.*?)${'"'}`, "g"))].forEach(match => {
                objects.push(LngObject[match[0].replace('"', '')]);
            });

            setObjects(objects);
        });
    }, [props.location]);

    const schemes = [""]; objects.forEach(object => { schemes.push(`theme-${object.scheme}`); })

    return (
        <div className="flex flex-col gap-2 align-middle text-skin-h_dark font-custom_bold text-lg">
            {objects.map((_: any, i: any) => {
                return (
                    <div className="flex flex-row justify-between align-middle w-full" key={objects[i].label}>
                        <label htmlFor={objects[i].label}> {objects[i].label} </label>
                        <input type="radio" name={objects[i].group}
                            checked={props.scheme === objects[i].scheme} value={objects[i].scheme} readOnly
                            onClick={(e) => {
                                props.scheme_function(e.currentTarget.value);
                                if (schemes.includes(props.theme)) { props.theme_function(`theme-${e.currentTarget.value}`) }
                            }} />
                    </div>
                );
            })}
        </div >
    );
}