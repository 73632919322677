const heroSVG = (props: any) => (
    <svg
        width={1440}
        height={755}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <path d="M-5.5 755V-8H1440v453.5L-5.5 755Z" className="fill-skin-hero_fill transition-colors duration-500" />
    </svg>
)

export default heroSVG
