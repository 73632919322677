import { Pagination } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';
import 'swiper/css/autoplay';
import SwiperCore, { Autoplay } from 'swiper';

import "../../../index.css";
import RequestLngObject from '../../Modules/Loader/requestLngObject';
import React from 'react';
import requestConfigObj from '../../..';

SwiperCore.use([Autoplay, Pagination]);

interface StarProps {
    range?: number;
    amount: number;

    dimension_styling?: string;
    custom_styling?: string;
}

interface ReviewProps {
    user: string;
    review: string;
    rating: number;
}

const Reviews: React.FC = () => {
    const [objects, setObjects] = React.useState<Array<ReviewProps>>([]);
    const [isLoading, setIsLoading] = React.useState(true);
    const prop = "review_";

    React.useEffect(() => {
        RequestLngObject({ file: requestConfigObj.params.file }).then((LngObject: any) => {
            let objects: Array<ReviewProps> = [];
            [...JSON.stringify(LngObject).matchAll(new RegExp(`${prop}(.*?)${'"'}`, "g"))].forEach(match => {
                objects.push(LngObject[match[0].replace('"', '')]);
            });

            setObjects(objects);
            setIsLoading(false);
        });
    }, [prop]);

    if (isLoading) { return <> </>; }

    return (
        <Swiper
            spaceBetween={22} centeredSlides={true} grabCursor={true}
            autoplay={{ delay: 10000, disableOnInteraction: false }}
            pagination={{ clickable: true, dynamicBullets: true, }}
            modules={[Autoplay, Pagination]} className="mySwiper pb-10 p-3"
        >
            {objects.map((_: any, i: any) => {
                return (
                    <SwiperSlide key={`slide${i}`}>
                        <Review props={{
                            user: objects[i].user,
                            review: objects[i].review,
                            rating: objects[i].rating
                        }} />
                    </SwiperSlide>
                );
            })}

        </Swiper>
    );
};

const Review = ({ props }: { props: ReviewProps }) => {
    return (
        <>
            <div className="w-full flex flex-row justify-center align-middle absolute">
                <div className="w-[2.75rem] h-[2.75rem] md:w-[4.5rem] md:h-[4.5rem] png_icon bg-user z-10"> </div>
            </div>

            <div className="bg-skin-modal_bg
            w-full h-[23rem] mt-9 rounded-3xl review_shadow px-16
            flex flex-col justify-center align-middle text-center">
                <div className="font-custom_bold text-3xl h-1/3 flex flex-col justify-center align-middle pt-8 text-skin-h_dark"> {props.user} </div>
                <div className="font-custom_regular text-xl my-1 h-1/3 flex flex-col justify-center align-middle text-skin-p_dark"> "{props.review}" </div>
                <div className="h-1/3 flex flex-col justify-center align-middle pb-8"> <Stars props={{ amount: props.rating }} />  </div>
            </div>
        </>
    );
}

const Stars = ({ props }: { props: StarProps }) => {
    return (
        <div className="flex flex-row gap-6 w-full justify-center align-middle">
            {Array.from({ length: (props.range === undefined ? 5 : (props.range > 0 ? props.range : 5)) }, (_, i) => {
                return (
                    <svg key={`star${i}`} viewBox="0 0 28 26" fill="none" xmlns="http://www.w3.org/2000/svg"
                        className={
                            `${props.custom_styling === undefined ? "" : props.custom_styling}
                            ${props.dimension_styling === undefined ? "h-7 w-7" : props.dimension_styling}
                            ${props.custom_styling === undefined ? "" : props.custom_styling}`
                        }>
                        {
                            (() => {
                                if (i < (props.amount > 0 ? props.amount : 0)) {
                                    return <path fillRule="evenodd" clipRule="evenodd" d="M12.3842 1.28095C12.982 -0.156312 15.018 -0.156313 15.6158 1.28095L18.3918 7.95524L25.5972 8.53289C27.1489 8.65729 27.7781 10.5937 26.5959 11.6063L21.1061 16.3089L22.7833 23.3402C23.1445 24.8544 21.4973 26.0511 20.1689 25.2397L14 21.4718L7.83111 25.2397C6.50269 26.0511 4.8555 24.8544 5.21667 23.3402L6.8939 16.3089L1.4041 11.6063C0.221916 10.5937 0.85108 8.65729 2.40273 8.53289L9.60818 7.95524L12.3842 1.28095Z"
                                        className='fill-skin-stars_active' />
                                } else {
                                    return <path fillRule="evenodd" clipRule="evenodd" d="M12.3842 1.28095C12.982 -0.156312 15.018 -0.156313 15.6158 1.28095L18.3918 7.95524L25.5972 8.53289C27.1489 8.65729 27.7781 10.5937 26.5959 11.6063L21.1061 16.3089L22.7833 23.3402C23.1445 24.8544 21.4973 26.0511 20.1689 25.2397L14 21.4718L7.83111 25.2397C6.50269 26.0511 4.8555 24.8544 5.21667 23.3402L6.8939 16.3089L1.4041 11.6063C0.221916 10.5937 0.85108 8.65729 2.40273 8.53289L9.60818 7.95524L12.3842 1.28095Z"
                                        className='fill-skin-stars_inactive' />
                                }
                            })()
                        }
                    </svg>
                );
            })}

        </div>
    );
}

export default Reviews;