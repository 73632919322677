import React, { useState, useEffect, useMemo, useRef, useCallback } from 'react';
import { Link } from "react-scroll";

import useWindowDimensions from "../Other/viewport";
import requestConfigObj from '../../../index';
import RequestLngObject from '../Loader/requestLngObject';

import KNVLogo from "../SVGs/icons/knvlogo";
import MailSVG from "../SVGs/icons/mailSVG";
import PhoneSVG from "../SVGs/icons/phoneSVG";

import "../../../index.css";
import Modal from './swipeable-modal';
import { ThemeSettings } from '../Other/theme_settings';
import MoonSVG from '../SVGs/icons/moonSVG';
import { ThemeContext } from '../Other/context';
import SunSVG from '../SVGs/icons/smallsunSVG';

interface LinksInterface { tag: string, href: string }
export interface NavSettingsInterface { anim_bars: string, anim_menu: string, anim_menu_items: string, toggleMenu: boolean };
const default_nav_settings: NavSettingsInterface = { anim_bars: "", anim_menu: "h-0", anim_menu_items: "z-[-1] cursor-none md:cursor-pointer pointer-events-none md:pointer-events-auto opacity-0 delay-0 duration-200", toggleMenu: false };

function setLinks() {
    const links: Array<LinksInterface> = []; let sections = document.querySelectorAll("[data-main-section]");
    if (sections && sections.length > 0) {
        sections.forEach(element => {
            let attribute = element.getAttribute("data-main-section");
            if (attribute) { let id = attribute.replaceAll(" ", "").toLowerCase(); element.id = id; links.push({ tag: `${attribute}`, href: `${id}` }); }
        });
    }
    return links;
}

const Navbar: React.FC = () => {
    const burgerBars = 3;
    const [navSettings, setNavSettings] = useState<NavSettingsInterface>(default_nav_settings);
    const [navMenuItems, setNavMenuItems] = useState<Array<LinksInterface>>([]);
    const [pop_up, setPopUp] = useState(false);
    const { width, threshold_md } = useWindowDimensions();

    const [phone_number, setPhoneNumber] = useState("");
    const [mail_address, setMailAddress] = useState("");

    const sectionsRef = useRef<NodeListOf<Element>>(null);

    useEffect(() => {
        RequestLngObject({ file: requestConfigObj.params.file }).then((LngObject) => {
            setPhoneNumber(LngObject["phone_number"]); setMailAddress(LngObject["contact_send_to"]);
        });

        if (sectionsRef.current !== document.querySelectorAll("[data-main-section]")) {
            setNavMenuItems(setLinks());
        }
    }, [setNavMenuItems]);

    const updateMenu = useCallback(() => {
        if (!navSettings.toggleMenu) {
            setNavSettings((prev) => ({
                ...prev,
                anim_bars: "hamburger-on-active",
                anim_menu: "h-screen",
                anim_menu_items: "opacit-100 delay-300 duration-300",
            }));
        } else {
            setNavSettings((prev) => ({
                ...prev,
                anim_bars: "",
                anim_menu: "h-0",
                anim_menu_items:
                    "z-[-1] cursor-none md:cursor-pointer pointer-events-none md:pointer-events-auto opacity-0 delay-0 duration-200",
            }));
        }
        document.body.classList.toggle(`nav-overflow-hidden`);
        setToggleMenu(!navSettings.toggleMenu); 
    }, [navSettings.toggleMenu]);

    const updateAnimations = (anim_bars: string, anim_menu: string, anim_menu_items: string) => { setNavSettings(prev => ({ ...prev, anim_bars: anim_bars, anim_menu: anim_menu, anim_menu_items: anim_menu_items })); }
    const setToggleMenu = (mode: boolean) => { setNavSettings(prev => ({ ...prev, toggleMenu: mode })); }

    const navLinks = useMemo(() => {
        return navMenuItems.map((link: LinksInterface) => {
            return (
                <li key={link.tag} className={`md:ml-7 md:my-0 my-7 transition-all duration-500 ease-in-out ${navSettings.anim_menu_items} md:opacity-100 md:z-auto`}>
                    <Link href={`#${link.href}`} to={link.href} spy={true} smooth={true} offset={-50} duration={500} onClick={() => { updateMenu() }}
                        className="text-skin-nav_primary_light font-custom_regular md:hover:text-skin-h_light duration-500 cursor-pointer">
                        {link.tag}
                    </Link>
                </li>
            );
        });
    }, [navMenuItems, navSettings.anim_menu_items, updateMenu]);


    return (
        <ThemeContext.Consumer>
            {(data) => {
                return (
                    <>
                        {(pop_up && width < threshold_md) &&
                            <Modal modal_state_function={setPopUp} down_swipe_limiter={2} opacity_modifier={0.2} wrapper_classes={"fixed w-full z-[100] bottom-0"}>
                                <ThemeSettings />
                            </Modal>
                        } {(pop_up && width > threshold_md) && <div onClick={() => { setPopUp(!pop_up) }} className="fixed w-full h-full left-[50%] top-[50%] -translate-x-1/2 -translate-y-1/2 cursor-default z-[50]"> </div>}

                        <nav className=" top-0 left-0 right-0 w-full z-50 md:absolute sticky">
                            <div className="md:flex items-center justify-between 4xl:px-[25vw] 3xl:px-[11vw] 2xl:px-[9vw] xl:px-20 px-7 md:py-4 bg-skin-nav shadow-md md:shadow-none z-50 transition-colors duration-500 ease-in">
                                <div className="py-3 md:py-0 flex justify-between items-center w-full md:w-auto md:block">
                                    <div className="cursor-pointer w-8 h-8 md:w-14 md:h-14">
                                        <Link aria-label='logo' id='logo' tabIndex={-1} href='#hero' to="hero" spy={true} smooth={true} offset={50} duration={500} onClick={() => {
                                            if (width <= threshold_md) {
                                                if (navSettings.toggleMenu) {
                                                    updateAnimations("", "h-0", "z-[-1] opacity-0 delay-0 duration-200");
                                                    document.body.classList.toggle(`nav-overflow-hidden`, false);
                                                    setToggleMenu(false);
                                                }
                                            } else { return; }
                                        }}>
                                            <KNVLogo />
                                        </Link>
                                    </div>

                                    <div className={`flex justify-between flex-col items-start md:hidden cursor-pointer h-4 ${navSettings.anim_bars}`} onClick={updateMenu}>
                                        {Array.from({ length: burgerBars }, (_, i) => {
                                            return <span key={`burger_bar${i}`} className={`block ${(i === burgerBars - 1) ? "w-[14px]" : "w-[22px]"} h-[2px] bg-skin-nav_primary_dark rounded-2xl transition-all duration-300`}> </span>
                                        })}
                                    </div>

                                </div>

                                <ul className={`md:flex md:items-center md:pb-0 w-full md:w-auto absolute md:static bg-skin-nav md:z-auto z-50 shadow-xl md:shadow-none left-0 md:pl-0 pl-9 transition-all duration-500 ease-in ${navSettings.anim_menu} md:h-auto`}> {navLinks} </ul>

                                <div className={`absolute mt-60 md:relative md:mt-0 md:ml-0 transition-all ease-in ${navSettings.anim_menu_items} md:opacity-100 z-50 md:z-auto`}>

                                    <div className='flex flex-col md:flex-row-reverse gap-9 md:gap-3'>

                                        <div className='flex flex-row gap-2 ml-2 justify-start align-middle'>
                                            {width < threshold_md && <h3 className='text-skin-nav_primary_light font-custom_regular md:hover:text-skin-h_light duration-500 cursor-pointer' onClick={() => { setPopUp(!pop_up) }}> Theme </h3>}
                                            <div className="w-[24px] h-[24px] flex justify-center items-center">
                                                <div className="flex justify-center items-center scale-[.9] md:scale-100 w-[16px] h-[16px] relative">
                                                    {data.theme_data.theme === "theme-base" && <SunSVG onClick={() => { setPopUp(!pop_up) }} />}
                                                    {data.theme_data.theme !== "theme-base" && <MoonSVG onClick={() => { setPopUp(!pop_up) }} />}
                                                    {(pop_up && width > threshold_md) &&
                                                        <Modal modal_state_function={setPopUp} backgdrop={false} handle_overflow={false} wrapper_classes={"absolute top-[1.5rem] right-[-.25rem]"}>
                                                            <ThemeSettings />
                                                        </Modal>
                                                    }
                                                </div>
                                            </div>
                                        </div>

                                        <div className='flex flex-row gap-7 items-center ml-3'>
                                            <a id="mail_link" href={`mailto:${mail_address}`} className="w-[16px] h-[16px] flex justify-center items-center" aria-label="Mail">
                                                <div className="flex justify-center items-center md:scale-100 w-[16px] h-[16px]">
                                                    <MailSVG />
                                                </div>
                                            </a>

                                            <a id="phone_link" href={`tel:${phone_number}`} className="w-[16px] h-[16px] flex justify-center items-center" aria-label="Phone">
                                                <div className="flex justify-center items-center scale-[.9] md:scale-100 w-[16px] h-[16px]">
                                                    <PhoneSVG />
                                                </div>
                                            </a>
                                        </div>

                                    </div>

                                </div>

                            </div>

                        </nav >
                    </>
                );
            }}

        </ThemeContext.Consumer>
    );
}


export default Navbar;
