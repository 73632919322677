import ReactDOM from "react-dom/client";
import { useEffect, useState } from "react";

import * as serviceWorkerRegistration from './serviceWorkerRegistration';

import XMLHttpRequest from "./Components/Modules/Loader/loader";
import { RequestConfig, RequestConfigParams } from './Components/Modules/Loader/request_config';
import { ThemeContext } from "./Components/Modules/Other/context";

import Navbar from "./Components/Modules/Interfaces/navbar";
import Hero from "./Components/Sections/Hero/hero";
import Services from "./Components/Sections/Services/services";
import About from "./Components/Sections/About/about";
import Packages from "./Components/Sections/Packages/packages";
import Contact from "./Components/Sections/Contact/contact";

import "./index.css";
import BackgroundSVG from "./Components/Modules/SVGs/other/backgroundSVG";
import useWindowDimensions from "./Components/Modules/Other/viewport";
import MobileBackgroundSVG from "./Components/Modules/SVGs/other/mobileBackgroundSVG";
import FooterTop from "./Components/Sections/Footer/footer_top";
import Footer from "./Components/Sections/Footer/footer";

const params: RequestConfigParams = { file: './Assets/JSON/data.json' };
const requestConfigObj = new RequestConfig(params);
Object.freeze(requestConfigObj);

const KNVMediaDOM = () => {
  XMLHttpRequest({ props: { file: requestConfigObj.params.file, attribute: "data-tag" } });
  const { width, threshold_sm, threshold_4xl } = useWindowDimensions();

  const [theme, setTheme] = useState("theme-base");
  useEffect(() => {
    if (typeof localStorage !== 'undefined' && localStorage.getItem('theme') !== null) {
      setTheme(localStorage.getItem('theme') || "theme-base");
      document.documentElement.className = ''; document.documentElement.classList.toggle(theme)
    }
    const observer = new MutationObserver(mutations => {
      for (const mutation of mutations) {
        if (mutation.type === "attributes" && mutation.attributeName === "class") { setTheme(document.documentElement.classList.toString()); break; }
      }
    }); observer.observe(document.documentElement, { attributes: true }); return () => { observer.disconnect(); };
  }, [theme]);

  return (
    <ThemeContext.Provider value={{ theme_data: { theme: theme } }}>
      <Navbar />
      <Hero />
      <Services />
      <About />
      <div className="h-full w-full">
        {(() => {
          if (width < threshold_sm) { return (<MobileBackgroundSVG className="absolute z-[-1] mt-[22rem]" />); }
          else if (width < threshold_4xl) { return (<BackgroundSVG className="absolute z-[-1] mt-[22rem]" />); }
        })()}
        <Packages />
        <Contact />
      </div>
      <FooterTop />
      <Footer />
    </ThemeContext.Provider>
  );
}

export default requestConfigObj;

window.onerror = function (message, source, lineno, colno, error) {
  return true;
};

serviceWorkerRegistration.register();

const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement);
root.render(<KNVMediaDOM />);

