const MobileBackgroundSVG = (props: any) => (
    <svg
        viewBox="0 0 392 1727"
        preserveAspectRatio="none"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <path d="M392.5 0-5 89v1563l397.5 75.5V0Z" className="fill-skin-hero_fill transition-colors duration-500" />
    </svg>
)

export default MobileBackgroundSVG
