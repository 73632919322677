import React from "react";
import Button from "../../Modules/Interfaces/button";
import Modal from "../../Modules/Interfaces/swipeable-modal";
import { ContactForm } from "../../Modules/Other/contact_form";

const FooterTop = () => {
    const [contact_form, setContact_form] = React.useState<boolean>(false);

    return (
        <section>
            {contact_form &&
                <Modal modal_state_function={setContact_form}
                    down_swipe_limiter={2}
                    wrapper_classes={"fixed w-full z-[100] left-1/2 bottom-0 md:bottom-auto md:top-1/2 -translate-x-1/2 md:-translate-y-1/2"}>
                    <div className="md:absolute md:top-[50%] md:left-[50%] md:-translate-x-1/2 md:-translate-y-[50%] md:w-auto h-auto md:min-w-[40rem] bg-skin-modal_bg
                    pop_up w-full rounded-3xl md:rounded-2xl rounded-b-none md:rounded-b-2xl cursor-default">
                        <div className="pop_up flex md:hidden flex-row w-full justify-center align-middle pt-[.5rem]">
                            <div className="w-[2.3rem] h-[.33rem] bg-skin-contrast rounded-full opacity-[.175]"> </div>
                        </div>
                        <ContactForm props={{ toggle_function: () => { setContact_form(!contact_form); } }} />
                    </div>
                </Modal>
            }
            <div className="4xl:px-[25vw] 3xl:px-[11vw] 2xl:px-[9vw] xl:px-20 px-7
            pb-24 pt-32 xl:pt-6 3xl:pt-48 flex flex-row justify-between w-full">
                <div className="flex flex-col gap-8 xl:w-[40%]">
                    <div className="w-full flex justify-start align-baseline">
                        <Button props={{
                            data_tag: "footer_inquiry_button",
                            text_styling: "text-xl text-skin-footer_button_text_color xl:text-2xl font-custom_bold",
                            onclick: () => { setContact_form(!contact_form); }
                        }} />
                    </div>
                    <div data-tag="footer_inquiry_paragraph" className="font-custom_regular text-skin-h_dark 
                    text-lg xl:text-xl">

                    </div>
                </div>
            </div>
        </section>
    );
}

export default FooterTop