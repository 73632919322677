const PhoneBigSVG = (props: any) => (
  <svg
    viewBox="0 0 1000 1000"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M208.104 14.962c0-8.131-7.243-14.723-16.177-14.723H19.177C10.243.239 3 6.83 3 14.962v334.045c0 8.131 7.243 14.723 16.177 14.723h172.75c8.934 0 16.177-6.592 16.177-14.723V14.962Z"
      className="fill-skin-hero_svg_gradient transition-all duration-500"
      shapeRendering="auto"
    />
    <g>
      <path
        d="M62.898 10.45c.56 0 1.098.202 1.494.562.396.36.619.849.619 1.358 0 3 1.31 5.875 3.64 7.995 2.333 2.12 5.495 3.312 8.792 3.312H133.1c3.297 0 6.459-1.191 8.791-3.312 2.331-2.12 3.641-4.996 3.641-7.994 0-.51.222-.999.619-1.36.396-.36.933-.562 1.493-.562h33.687c4.425 0 8.669 1.599 11.798 4.444 3.129 2.846 4.887 6.706 4.887 10.73v312.723c0 4.024-1.758 7.884-4.887 10.73-3.129 2.845-7.373 4.444-11.798 4.444H29.212c-4.425 0-8.67-1.599-11.798-4.444-3.13-2.846-4.887-6.706-4.887-10.73V25.623c0-4.024 1.758-7.884 4.887-10.73 3.129-2.845 7.373-4.444 11.798-4.444h33.686Z"
        shapeRendering="auto"
        className="fill-skin-hero_svg_primary transition-all duration-500"
      />
    </g>
    <defs>
      <linearGradient
        id="base"
        x1={105.552}
        y1={0.239}
        x2={105.552}
        y2={363.73}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FFEFFF" />
        <stop offset={1} stopColor="#6D00FF" />
      </linearGradient>

      <filter
        id="b"
        x={0.527}
        y={2.449}
        width={209.49}
        height={367.071}
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity={0} result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feMorphology
          radius={4}
          operator="dilate"
          in="SourceAlpha"
          result="effect1_dropShadow_12_49"
        />
        <feOffset dy={4} />
        <feGaussianBlur stdDeviation={4} />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
        <feBlend in2="BackgroundImageFix" result="effect1_dropShadow_12_49" />
        <feBlend
          in="SourceGraphic"
          in2="effect1_dropShadow_12_49"
          result="shape"
        />
      </filter>
    </defs>
  </svg>
)

export default PhoneBigSVG
