import React from "react";
import { Link } from 'react-scroll';
import requestConfigObj from "../../../index";
import RequestLngObject from "../Loader/requestLngObject";

import "../../../index.css";

export interface ButtonProps {
    data_tag?: string;
    content?: string | React.ReactNode;
    text?: string;

    href?: string;
    onclick?: (event: React.MouseEvent<HTMLButtonElement>) => void;

    dimension_styling?: string;
    text_styling?: string;
    custom_styling?: string;
    custom_animation?: string;
    bg_color?: string;
}

export interface ButtonWrapperProps {
    href?: string;
    children: JSX.Element | JSX.Element[];
}

const Button = ({ props }: { props: ButtonProps }) => {
    const [object, setObject] = React.useState<any>("");

    React.useEffect(() => {
        RequestLngObject({ file: requestConfigObj.params.file }).then((LngObject: any) => {
            setObject(LngObject);
        });
    }, []);
    return (
        <ButtonWrapper href={props.href}>
            <button formNoValidate onClick={props.onclick !== undefined ? props.onclick : () => { }} className={
                `${props.custom_styling === undefined ? "rounded-md flex items-center flex-col justify-center align-middle text-center shadow-md shadow-[rgba(0,0,0,0.5)] leading-none" : props.custom_styling}
                ${props.custom_animation === undefined ? "active:translate-y-[1px] hover:scale-[1.005] active:shadow-sm active:shadow-[rgba(0,0,0,0.7)] transition-none duration-0 md:transition-all md:duration-100" : props.custom_animation}
                ${props.dimension_styling === undefined ? "px-7 py-4" : props.dimension_styling}
                ${props.text_styling === undefined ? "text-skin-button_text_color text-xl font-custom_regular" : props.text_styling}
                ${props.custom_styling === undefined ? "" : props.custom_styling}
                ${props.bg_color === undefined ? "bg-skin-button_bg_color" : props.bg_color}`
            }>
                {(() => {
                    if (props.content !== undefined) {
                        return props.content;
                    } else if (props.data_tag !== undefined || props.text !== undefined) {
                        return (
                            <p className="flex flex-col justify-center align-middle items-center h-full
                            font-custom_bold leading-none translate-y-[1.5px]">
                                {(props.data_tag !== undefined ? object[props.data_tag] : (props.text !== undefined ? props.text : ""))}
                            </p>
                        );
                    } else { return <p> </p>; }
                })()}
            </button>
        </ButtonWrapper>
    );
}

export function ButtonWrapper(props: ButtonWrapperProps) {
    if (props.href !== undefined) {
        return (<Link tabIndex={-1} href={`#${props.href}`} to={props.href} spy={true} smooth={true} offset={50} duration={500}> {props.children} </Link>);
    } else { return <> {props.children} </>; }
}

export default Button;