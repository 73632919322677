import React from "react";
import requestConfigObj from "../../../../index";
import RequestLngObject from "../../../Modules/Loader/requestLngObject";
import { PackageProps } from "../package";

export default function ModalItems({ props }: { props: PackageProps }) {
    const [objects, setObjects] = React.useState<Array<string>>([]);
    const [extras, setExtras] = React.useState<Array<string>>([]);
    const items = props.package_options?.replace(/[^0-9.]+/g, ',').split(",");
    const prop = "item_package_option_", extras_prop = "package_add_ons";

    React.useEffect(() => {
        RequestLngObject({ file: requestConfigObj.params.file }).then((LngObject: any) => {
            let objects: Array<string> = [];
            [...JSON.stringify(LngObject).matchAll(new RegExp(`${prop}(.*?)${'"'}`, "g"))].forEach(match => {
                objects.push(LngObject[match[0].replace('"', '')]);
            });
            setObjects(objects); setExtras(LngObject[extras_prop].replace(/[^0-9.]+/g, ',').split(","));
        });
    }, [prop]);

    const [item_array] = React.useState<Array<JSX.Element>>([]);

    objects.map((_: any, i: any) => {
        const isInItems = items?.indexOf(i.toString()) !== -1, isInExtras = extras.indexOf(i.toString()) !== -1;

        const item = <ModalItem key={`${objects[i]}`}
            props={{
                content: objects[i],
                type: `package_item ${isInExtras ? "package_add_on_item" : ""}` +
                    (isInItems && isInExtras ? " item_active cursor-default" : isInExtras ? " item_inactive cursor-default hidden" :
                        !isInItems && !isInExtras ? " hidden" : ""),
                onclick: (isInExtras ? (e) => { e.currentTarget.classList.toggle("item_inactive"); e.currentTarget.classList.toggle("item_active"); } : () => { })
            }} />;
        if (isInItems) { item_array.unshift(item); } else if (isInExtras) { item_array.push(item); } return (<></>);
    });

    return (<div className="flex flex-row justify-center align-center gap-3 flex-wrap modal-shadow pt-4 pb-2">
        {item_array.map((_: any, i: any) => { return (item_array[i]); })}
    </div>);
}

interface ModalItemProps {
    content?: string;
    type?: string;
    onclick?: (event: React.MouseEvent<HTMLButtonElement>) => void;
}

export function ModalItem({ props }: { props: ModalItemProps }) {
    return (
        <button className={`${props.type !== undefined ? props.type : ""} 
        px-3 pt-2 pb-1 rounded-lg bg-skin-modal_bg font-custom_bold text-[.75rem] md:text-[1rem] lg:text-[1rem] text-skin-h_dark modal_shadow`}
            onClick={props.onclick !== undefined ? props.onclick : () => { }}>
            {(props.content !== undefined ? props.content : "")}
        </button>
    );
}