import { useState, useRef } from 'react';
import { useSwipeable } from 'react-swipeable';

export type ModalParametars = {
    modal_state_function: (isOpen: boolean) => void,
    children: JSX.Element | JSX.Element[],
    wrapper_classes?: string | null,
    backgdrop?: boolean,
    handle_overflow?: boolean | undefined,
    up_swipe_limiter?: number,
    down_swipe_limiter?: number,
    custom_height?: number,
    custom_backgdrop?: string,
    small_touch_surface?: boolean,
    small_touch_surface_container_style?: string,
    opacity_modifier?: number,
    track_mouse?: boolean
}

export default function Modal(props: ModalParametars) {
    const [modalHeight, setModalHeight] = useState<number>(0);
    const [isSwiping, setIsSwiping] = useState<boolean>(false);
    const childrenRef = useRef<HTMLDivElement>(null);

    const down_limit = (props.down_swipe_limiter ? (props.down_swipe_limiter > 0 ? props.down_swipe_limiter : 1) : 2);
    const up_limit = (props.up_swipe_limiter ? (props.up_swipe_limiter > 0 ? props.up_swipe_limiter : 1) : window.innerHeight);
    const child_height = (props.custom_height ? (props.custom_height > 0 ? props.custom_height : 100) : (childrenRef !== null && childrenRef.current !== null ? childrenRef.current.offsetHeight : 100));
    const handle_overflow = (props.handle_overflow !== undefined ? props.handle_overflow : true);

    const handleSwipeMove = (event: any) => { setIsSwiping(true); setModalHeight(event.deltaY) }
    const handleSwipeEnd = () => { setIsSwiping(false); setModalHeight(0); }

    const handlers = useSwipeable({
        onSwiping: handleSwipeMove,
        onSwipedUp: handleSwipeEnd,
        onSwipedDown: () => {
            const halfChildrenHeight = child_height / down_limit;
            if (modalHeight < halfChildrenHeight) { return handleSwipeEnd(); }
            handleSwipeEnd(); props.modal_state_function(false);
            if (handle_overflow === true) { document.body.classList.remove(`modal-overflow-hidden`); }
        },
        onSwiped: handleSwipeEnd,
        trackMouse: (props.track_mouse !== undefined ? props.track_mouse : false),
    })

    if (handle_overflow === true) { document.body.classList.add(`modal-overflow-hidden`); }

    return (
        <>
            {(props.backgdrop !== undefined ? props.backgdrop : true) &&
                <div className={"fixed w-[101%] h-[101%] z-[100] top-[50%] left-[50%] -translate-x-1/2 -translate-y-1/2 " +
                    (props.custom_backgdrop !== undefined ? props.custom_backgdrop : "bg-[rgba(0,0,0,0.2)] backdrop-blur-[2px]")}
                    onClick={() => {
                        props.modal_state_function(false);
                        if (handle_overflow === true) { document.body.classList.remove(`modal-overflow-hidden`); }
                    }}>
                </div>
            }

            <div {...(props.small_touch_surface === undefined || props.small_touch_surface === false ? handlers : {})}>
                <div className={props.wrapper_classes !== null ? props.wrapper_classes : "absolute z-[100] left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2"}>
                    <div ref={childrenRef} style={{
                        transform: isSwiping ? `translateY(${modalHeight * (-1) > (window.innerHeight / up_limit) ? (window.innerHeight / up_limit) * (-1) : modalHeight}px)` : '',
                        transition: !isSwiping ? 'transform 300ms ease-in-out' : '',
                        opacity: 1 - ((modalHeight / window.innerHeight) * (props.opacity_modifier !== undefined ? props.opacity_modifier : 0.5))
                    }}>
                        <div className={(props.small_touch_surface_container_style !== undefined ? props.small_touch_surface_container_style : "")}>
                            {(props.small_touch_surface !== undefined && props.small_touch_surface === true) &&
                                <div className="pop_up flex flex-row w-full justify-center align-middle pt-[.5rem] pb-[.5rem]" {...handlers}>
                                    <div className="w-[15%] h-[.33rem] bg-skin-contrast rounded-full opacity-[.175] cursor-pointer"> </div>
                                </div>}
                            {props.children}
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
