export interface EmailInterface {
    send: (a: any) => Promise<any>;
    ajaxPost: (e: string, n: string) => Promise<string>;
    ajax: (e: string) => Promise<string>;
}

const Email: EmailInterface = {
    send: function (a) {
        return new Promise(function (n, e) {
            a.nocache = Math.floor(1e6 * Math.random() + 1);
            a.Action = "Send";
            const t = JSON.stringify(a);
            Email.ajaxPost("https://smtpjs.com/v3/smtpjs.aspx?", t)
                .then((response) => n(response))
                .catch((error) => e(error));
        });
    },
    ajaxPost: async function (e, n) {
        const response = await fetch(e, {
            method: "POST",
            headers: {
                "Content-Type": "application/x-www-form-urlencoded",
            },
            body: n,
        });
        return await response.text();
    },
    ajax: async function (e) {
        const response = await fetch(e);
        return await response.text();
    },
};

export default Email