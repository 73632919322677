import { createContext } from 'react';

interface ServicesModeContextInterface {
    service_data: {
        count?: number;
    };
}

interface ThemeContextInterface { theme_data: { theme?: string; }; }

export const ServicesModeContext = createContext<ServicesModeContextInterface>({ service_data: {} });
export const ThemeContext = createContext<ThemeContextInterface>({ theme_data: {} });