const LaptopSVG = (props: any) => (
    <svg
        viewBox="0 0 1000 1000"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <path
            d="M297.181 17.85a14.213 14.213 0 0 0-14.216-14.213H28.112A14.218 14.218 0 0 0 13.896 17.85v158.521H297.18V17.851h.001Z"
            className="fill-skin-hero_svg_gradient transition-all duration-500"
            shapeRendering="auto"
        />
        <g filter="gray">
            <path
                d="M123.991 8.819c1.433 0 2.734.842 3.326 2.153.323.736.785 1.41 1.362 1.99a6.744 6.744 0 0 0 4.781 1.99h43.726a6.746 6.746 0 0 0 4.781-1.99 6.792 6.792 0 0 0 1.371-1.986 3.64 3.64 0 0 1 3.316-2.147c15.351-.01 90.015-.01 90.015-.01a14.43 14.43 0 0 1 10.229 4.257 14.57 14.57 0 0 1 4.237 10.278v137.04H19.51V23.354a14.57 14.57 0 0 1 4.237-10.278 14.432 14.432 0 0 1 10.23-4.257h90.014Z"
                className="fill-skin-hero_svg_primary transition-all duration-500"
                shapeRendering="auto"
            />
        </g>
        <path
            d="M128.668 168.599c.929 0 1.681.762 1.681 1.703v.075c0 .779.624 1.411 1.393 1.411h47.592c.769 0 1.393-.632 1.393-1.411v-.075c0-.941.753-1.703 1.681-1.703h124.648a3.92 3.92 0 0 1 2.789 1.17 4.022 4.022 0 0 1 1.155 2.824v7.557c0 1.06-.416 2.076-1.155 2.825a3.92 3.92 0 0 1-2.789 1.17H4.021a3.92 3.92 0 0 1-2.789-1.17 4.02 4.02 0 0 1-1.155-2.825v-7.557a4.02 4.02 0 0 1 1.155-2.824 3.92 3.92 0 0 1 2.79-1.17h124.647-.001Z"
            className="fill-skin-hero_svg_secondary transition-all duration-500"
            shapeRendering="auto"
        />
        <defs>
            <linearGradient
                id="base"
                x1={155.539}
                y1={3.637}
                x2={155.539}
                y2={176.372}
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#FFEFFF" />
                <stop offset={1} stopColor="#6D00FF" />
            </linearGradient>

            <linearGradient
                id="theme-dark"
                x1={329.11}
                y1={0}
                x2={360.11}
                y2={365.526}
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#332852" />
                <stop offset={0.49} stopColor="#110f19" />
            </linearGradient>

            <linearGradient
                id="theme-black"
                x1={329.11}
                y1={0}
                x2={329.11}
                y2={365.526}
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#1d094d" />
                <stop offset={0.49} stopColor="#020006" />
            </linearGradient>

            <filter
                id="b"
                x={7.51}
                y={0.819}
                width={295.626}
                height={175.575}
                filterUnits="userSpaceOnUse"
                colorInterpolationFilters="sRGB"
            >
                <feFlood floodOpacity={0} result="BackgroundImageFix" />
                <feColorMatrix
                    in="SourceAlpha"
                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                    result="hardAlpha"
                />
                <feMorphology
                    radius={4}
                    operator="dilate"
                    in="SourceAlpha"
                    result="effect1_dropShadow_12_47"
                />
                <feOffset dy={4} />
                <feGaussianBlur stdDeviation={4} />
                <feComposite in2="hardAlpha" operator="out" />
                <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.5 0" />
                <feBlend in2="BackgroundImageFix" result="effect1_dropShadow_12_47" />
                <feBlend
                    in="SourceGraphic"
                    in2="effect1_dropShadow_12_47"
                    result="shape"
                />
            </filter>
        </defs>
    </svg>
)

export default LaptopSVG
