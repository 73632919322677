import React from "react";
import requestConfigObj from "../../..";
import RequestLngObject from "../../Modules/Loader/requestLngObject";
import Modal from "../../Modules/Interfaces/swipeable-modal";

const Footer = () => {

    const [info, setInfo] = React.useState<boolean>(false);
    const [info_content, setInfo_content] = React.useState<string>("");

    React.useEffect(() => {
        const element = document.querySelector("[data-info-more]");
        if (element) { element.innerHTML = info_content; }
    },);

    const [facebookURL, setFacebookURL] = React.useState("#"), [linkedinURL, setLinkedinURL] = React.useState("#"), [instagramURL, setInstagramURL] = React.useState("#");
    const [privacy_policy, setPrivacy_policy] = React.useState(""), [terms, setTerms] = React.useState("");

    RequestLngObject({ file: requestConfigObj.params.file }).then((LngObject) => {
        setFacebookURL(LngObject["footer_link_facebook"]); setLinkedinURL(LngObject["footer_link_linkedin"]); setInstagramURL(LngObject["footer_link_instagram"]);
        setPrivacy_policy(LngObject["privacy_policy"]); setTerms(LngObject["terms_and_conditions"]);
    });

    return (
        <section>
            {info &&
                <Modal modal_state_function={setInfo}
                    down_swipe_limiter={3}
                    track_mouse={true}
                    small_touch_surface={true}
                    small_touch_surface_container_style={"bg-skin-modal_bg pop_up w-full md:w-[50vw] self-center h-[80vh] rounded-3xl rounded-b-none cursor-default p-5 pt-0"}
                    wrapper_classes={"fixed md:w-auto w-full z-[100] left-1/2 bottom-0 -translate-x-1/2 flex justify-center align-middle"}>
                    <pre data-info-more className="whitespace-pre-line overflow-auto scrollbar-thumb-[#1c1c1c] scrollbar-track-transparent scrollbar-thin h-full mt-1 font-custom_regular text-skin-h_dark">
                    </pre>
                </Modal>
            }
            <div className="4xl:px-[25vw] 3xl:px-[11vw] 2xl:px-[9vw] xl:px-20 px-7
             flex flex-col-reverse gap-6 md:gap-[auto] md:flex-row mb-12 justify-between h-full w-full font-custom_bold text-skin-gray md:text-lg">
                <div data-tag="footer_trademark"> </div>
                <div data-tag="footer_item_1" className="cursor-pointer" onClick={() => { setInfo_content(privacy_policy); setInfo(!info); }}> </div>
                <div data-tag="footer_item_2" className="cursor-pointer" onClick={() => { setInfo_content(terms); setInfo(!info); }}> </div>
                <div>
                    <ul className="flex flex-row gap-8 md:mt-[-.33rem]">
                        <li> <a id="facebookSVG" href={facebookURL} target={"_blank"} rel="noreferrer" aria-label="Facebook" tabIndex={-1}> <div className="flex justify-center items-center w-[32px] h-[32px] bg-facebook_icon"> </div> </a> </li>
                        <li> <a id="linkedInSVG" href={linkedinURL} target={"_blank"} rel="noreferrer" aria-label="LinkedIn" tabIndex={-1}> <div className="flex justify-center items-center w-[32px] h-[32px] bg-linkedin_icon"> </div> </a> </li>
                        <li> <a id="instagramSVG" href={instagramURL} target={"_blank"} rel="noreferrer" aria-label="Instagram" tabIndex={-1}> <div className="flex justify-center items-center w-[32px] h-[32px] bg-instagram_icon"> </div> </a> </li>
                    </ul>
                </div>
            </div>
        </section >
    );
}

export default Footer